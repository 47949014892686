import React, { useEffect, useState } from 'react';
import { Typography, Container, Paper, Link, Fab } from '@mui/material';
import { Brightness4, Brightness7 } from '@mui/icons-material';
import './App.css';

const NotFound = () => {
    const [theme, setTheme] = useState('dark');

    useEffect(() => {
        document.title = "Page Not Found";

        const handleContextMenu = (e) => e.preventDefault();
        const handleSelectStart = (e) => e.preventDefault();

        document.addEventListener('contextmenu', handleContextMenu);
        document.addEventListener('selectstart', handleSelectStart);

        const systemTheme = window.matchMedia('(prefers-color-scheme: dark)').matches ? 'dark' : 'light';
        setTheme(systemTheme);

        const handleThemeChange = (e) => {
            setTheme(e.matches ? 'dark' : 'light');
        };

        const darkThemeMediaQuery = window.matchMedia('(prefers-color-scheme: dark)');
        darkThemeMediaQuery.addEventListener('change', handleThemeChange);

        return () => {
            document.removeEventListener('contextmenu', handleContextMenu);
            document.removeEventListener('selectstart', handleSelectStart);
            darkThemeMediaQuery.removeEventListener('change', handleThemeChange);
        };
    }, []);

    const toggleTheme = () => {
        setTheme((prevTheme) => (prevTheme === 'dark' ? 'light' : 'dark'));
    };

    const rotateCursor = () => {
        const cursorImage = new Image();
        cursorImage.src = 'cursor.png';
        cursorImage.onload = () => {
          const canvas = document.createElement('canvas');
          const ctx = canvas.getContext('2d');
          canvas.width = cursorImage.width;
          canvas.height = cursorImage.height;
          ctx.translate(canvas.width / 2, canvas.height / 2);
          ctx.rotate(-10 * Math.PI / 180);
          ctx.drawImage(cursorImage, -cursorImage.width / 2, -cursorImage.height / 2);
          const rotatedCursor = canvas.toDataURL();
          const style = document.createElement('style');
          style.innerHTML = `* { cursor: url(${rotatedCursor}), auto; }`;
          document.head.appendChild(style);
        };
      };
    
      useEffect(() => {
        rotateCursor();
      }, []);

    return (
        <Container
            className="App"
            maxWidth={false}
            disableGutters
            sx={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                minHeight: '100vh',
                backgroundColor: theme === 'dark' ? '#121212' : '#ffffff',
                backgroundImage: theme === 'dark'
                    ? 'radial-gradient(#333 1px, transparent 1px)'
                    : 'radial-gradient(#ccc 1px, transparent 1px)',
                backgroundSize: '10px 10px',
                color: theme === 'dark' ? 'white' : '#121212',
                overflowX: 'hidden',
                p: 0,
            }}
        >
            <Paper
                className="App-header"
                sx={{
                    color: theme === 'dark' ? 'white' : '#121212',
                    backgroundColor: 'transparent',
                    boxShadow: 'none',
                    outline: 'none',
                    textAlign: 'left',
                }}
            >
                <Typography
                    variant="h1"
                    component="p"
                    gutterBottom
                    sx={{
                        fontSize: { lg: '10vw', md: '12vw', sm: '16vw', xs: '18vw' },
                        lineHeight: 1,
                        fontWeight: 700,
                        letterSpacing: { xs: '-0.05em', sm: '-0.05em', md: '-0.05em', lg: '-0.05em' },
                        textAlign: 'justify',
                        textDecoration: 'underline',
                        textDecorationColor: theme === 'dark' ? 'white' : '#121212',
                        textDecorationThickness: '0.1em',
                        textUnderlineOffset: '0.1em',
                        '-webkit-hyphens': 'auto',
                        '-moz-hyphens': 'auto',
                        '-ms-hyphens': 'auto',
                    }}
                >
                    🚫 404 - Page Not Found. 👎 Oops! 😢 It seems 🔗 you've hit a 🚧 dead end.{' '}
                    <Link href="/" sx={{ color: theme === 'dark' ? 'white' : '#121212', textDecoration: 'inherit' }}>
                        Return home 🏡
                    </Link> and 🗺️ let's get you 🔍 back on 🚀 track.
                </Typography>
            </Paper>
            <Fab
                color="primary"
                aria-label="toggle theme"
                onClick={toggleTheme}
                sx={{
                    position: 'fixed',
                    bottom: 16,
                    right: 16,
                    backgroundColor: theme === 'dark' ? 'orange' : '#1976d2',
                    color: 'white',
                    '&:hover, &:focus': {
                        backgroundColor: theme === 'dark' ? 'orange' : '#1976d2',
                    },
                }}
            >
                {theme === 'dark' ? <Brightness7 /> : <Brightness4 />}
            </Fab>
        </Container>
    );
};

export default NotFound;